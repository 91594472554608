
import Vue from 'vue'
import PromotionMultiMallView from '../../views/event/PromotionMultiMall.vue'
export default Vue.extend({
  name: 'PromotionMultiMall',
  components: { PromotionMultiMallView },
  data() {
    return {
      scrollLocation: 0,
    }
  },
})
