<!-- 카테고리 -->
<template>
  <div>
    <div
      class="fixed promotion-top fixed-content-area z-index-10"
      :class="`${headerAnimClass}`"
    >
      <div class="fill-width white d-flex align-center" style="height: 44px">
        <div class="d-flex justify-space-between fill-width align-center px-10">
          <v-icon small class="mr-4" @click="onClickPrevIcon">
            $vuetify.icons.prevIcon
          </v-icon>
          <span
            class="oneLine rixgo-extrabold align-center gray900--text font-g16-24"
            >{{ multiPromotionGroup.title }}</span
          >
          <div class="pb-1" @click="onClickShareIcon">
            <v-icon>$vuetify.icons.shareIcon</v-icon>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!datafetching" :class="`relative top-0  ${headerAnimClass}`">
      <v-skeleton-loader
        id="category_exhibition_main_image"
        class="relative fill-width"
        :height="$isMobile() ? '420' : '700'"
        type="image"
      ></v-skeleton-loader>
    </div>
    <div
      v-if="datafetching"
      :class="`relative category-header-top  ${headerAnimClass}`"
    >
      <div
        v-if="!usePromotionCheck"
        class="fill-width pt-20 pb-20"
        style="background: black"
      >
        <div class="white--text">
          <div class="d-flex justify-center font-g14-20 rixgo-bold pb-2">
            종료된 기획전입니다.
          </div>
          <div class="d-flex justify-center font-g12-18 rixgo-regular">
            할인율과 혜택이 적용되지 않을 수 있습니다.
          </div>
        </div>
      </div>
      <div
        v-for="item in multiPromotionGroup.topImageList"
        :key="item"
        class="fill-width"
      >
        <v-img eager :src="item" height="auto" />
      </div>
      <div v-if="usePromotionCheck" :class="`fill-width pt-15 pb-15 oneLine`">
        <div class="d-flex justify-center align-center pb-10">
          <div cless="font-g16-24 campton-medium gray900--text">COUNT DOWN</div>
        </div>
        <flip-countdown
          :deadline="promotionEndDt"
          :showDays="showDays"
          @timeElapsed="timeElapsedHandler"
        ></flip-countdown>
      </div>
      <div
        class="fill-width pb-16"
        :style="`background-color: ${multiPromotionGroup.promotionInfo.representItem.backgroundColor}`"
      >
        <v-carousel hide-delimiters :show-arrows="false" height="auto">
          <VueSlickCarousel
            ref="carousel"
            v-bind="carouselOption"
            :arrows="false"
            @afterChange="handleActive"
          >
            <div
              v-for="(itemList, index) in multiPromotionGroup.promotionInfo
                .representItem.searchProductList"
              :key="itemList.productNo"
              class="pt-10"
            >
              <ProductCardImage
                eventMallType="multi"
                :imageIndex="index"
                :targetImage="targetImage"
                :product="itemList"
                :aspectRatio="1"
                :gtagName="`collabo_${multiPromotionNo}_rolling_${$numbering(
                  index + 1
                )}`"
                @onClickBlurImage="onClickBlurImage"
              />
            </div>
          </VueSlickCarousel>
          <div class="d-flex align-center pt-8 pr-10 pl-10 white--text">
            <div class="font-g12-18 campton-bold pr-4">
              {{ active + 1 >= 10 ? active + 1 : '0' + (active + 1) }}
            </div>
            <v-progress-linear
              height="3"
              style="background: rgba(0, 0, 0, 0.25)"
              color="white"
              :value="
                ((active + 1) /
                  Math.ceil(
                    multiPromotionGroup.promotionInfo.representItem
                      .searchProductList.length
                  )) *
                100
              "
            ></v-progress-linear>
            <div class="font-g12-18 campton-bold pl-4">
              {{
                multiPromotionGroup.promotionInfo.representItem
                  .searchProductList.length >= 10
                  ? multiPromotionGroup.promotionInfo.representItem
                      .searchProductList.length
                  : '0' +
                    multiPromotionGroup.promotionInfo.representItem
                      .searchProductList.length
              }}
            </div>
          </div>
        </v-carousel>
      </div>
      <div class="fixed-bar-exhibition-event-multi-tab category-header-top">
        <v-tabs
          class=""
          background-color="default"
          color="default_t"
          grow
          fixed-tabs
          height="47"
          eager
          touchless
        >
          <div class="fill-width d-flex border-bottom-gray">
            <div
              v-for="(item, index) in multiPromotionGroup.promotionInfo
                .multiShopList"
              :key="item.index"
              :class="`fill-width d-flex justify-center align-center rixgo-bold font-g14-20 ${
                targetMallIndex === index ? 'black--text' : 'gray500--text'
              }`"
              :style="`border-top: 4px solid white; border-bottom: 4px solid white; ${
                targetMallIndex === index
                  ? 'border-bottom: 4px solid black'
                  : ''
              }`"
              @click="handleChange(index)"
            >
              <div
                :style="`${
                  index === 0 ? '' : 'border-left: 1px solid #eaebed;'
                } height: 45%;`"
                class="fill-width text-center"
              >
                {{ item.shopName }}
              </div>
            </div>
          </div>
        </v-tabs>
      </div>
      <div
        v-for="(item, index) in promotionMultiShopList"
        :id="'area-' + item.index"
        :key="item.index"
        class="pt-37"
      >
        <div class="pa-10 pb-10">
          <v-img eager :src="item.imageUrl" height="auto" class="rounded-lg" />
          <div class="d-flex align-center pt-8 pb-10">
            <div
              class="fill-width d-flex align-center"
              style="width: 73%"
              @click="moveToStore(item.shopId, index)"
            >
              <v-img
                :src="item.icon"
                class="rounded-circle"
                max-height="50"
                max-width="50"
              />
              <div class="txt_line pl-5">
                <div class="d-flex pb-2">
                  <div class="rixgo-semibold font-g14-20 pr-4">
                    {{ item.shopName }}
                  </div>
                  <div
                    v-for="(ages, index) in item.ages"
                    :key="index"
                    class="pr-2"
                  >
                    <div
                      class="fill-width fill-height d-flex align-center rixgo-regular font-g10-18 gray500--text rounded pl-2 pr-2"
                      style="background-color: #eaebed"
                    >
                      {{ ages }}
                    </div>
                  </div>
                </div>
                <div class="txt_line rixgo-regular font-g12-18 pr-8">
                  {{ item.description }}
                </div>
              </div>
            </div>
            <div
              class="text-align pt-1"
              style="width: 27%; border-left: 1px solid #eaebed"
            >
              <div
                class="d-flex justify-center align-center pb-2"
                @click="
                  toggleSubscribe(
                    item.shopId,
                    item.shopName,
                    item.isSubscribed,
                    index
                  )
                "
              >
                <v-img
                  :src="
                    item.isSubscribed
                      ? require(`./../../../assets/images/subscribeMultiMall.svg`)
                      : require(`./../../../assets/images/unSubscribeMultiMall.svg`)
                  "
                  max-height="18"
                  max-width="14"
                />
              </div>
              <div
                :class="`d-flex justify-center align-center campton-book gray500--text font-g12-18 ${
                  item.isSubscribed ? 'blue--text' : 'black--text'
                }`"
              >
                {{ subscribeCountArray[index] | handleTransform }}
              </div>
            </div>
          </div>
          <div
            v-for="(itemList, _index) in item.categoryList"
            :key="itemList.title"
            class=""
          >
            <div class="rixgo-bold font-g16-24 pt-20 pb-8">
              {{ itemList.title }}
            </div>
            <div class="d-flex align-content-center flex-wrap">
              <div
                v-for="productItem in itemList.searchProductList"
                :key="productItem.productNo"
                class="width-50 px-1 pb-10"
              >
                <ProductCardY
                  :product="productItem"
                  :useSoldOut="true"
                  :gtagName="`collabo_${multiPromotionNo}_cate${$numbering(
                    index + 1
                  )}_${$numbering(_index + 1)}`"
                  @isLikeChange="(yn) => isLikeChange(yn, index, _index)"
                />
              </div>
            </div>
            <div
              v-if="
                (itemList.paging.page + 1) * itemList.paging.limit <
                multiPromotionGroup.promotionInfo.multiShopList[index]
                  .categoryList[_index].searchProductList.length
              "
              class="pt-5 pb-5"
            >
              <div
                class="fill-width d-flex align-center justify-center"
                style="border: 1px solid blue; height: 47px; border-radius: 4px"
                @click="viewMore(index, _index)"
              >
                <div class="font-g13-18 rixgo-black blue--text">
                  {{ itemList.title }}
                </div>
                <div class="font-g13-18 rixgo-regular blue--text pl-2">
                  더보기
                  <v-icon class="pl-3 pb-1" size="16"
                    >$vuetify.icons.downIcon</v-icon
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sharing
        :content="multiPromotionGroup.shareInfo.description"
        :shareItem="multiPromotionGroup.shareInfo"
        :show="share"
        :url="multiPromotionGroup.shareInfo.url"
        :store="multiPromotionGroup.title"
        sheetTitle="기획전"
        @onClose="onCloseShare"
      />
      <div class="font-g16-24 pa-10 rixgo-bold">다른 기획전</div>
      <div class="pl-10 pr-10">
        <div
          v-for="(item, index) in multiPromotionGroup.bottomBannerList"
          :key="index"
        >
          <v-img
            :src="item.imageUrl"
            :lazy-src="require('@/assets/images/img_back.png')"
            :aspect-ratio="335 / 98"
            class="mb-8 border-radius-8"
            @click="goExhibitionList(item.exhibitionNo, index)"
          />
        </div>
      </div>
      <div class="pt-20 pb-30 pl-10 pr-10">
        <CellookButton
          :buttonTitle="`MD's Pick 보기`"
          :background-color="`#003399`"
          :buttonTitleTextClass="`font-g14-20 rixgo-bold`"
          :text-color="`white`"
          @click="goMainMDSPick()"
        />
      </div>
    </div>
    <Alert
      :show="alert.show"
      :content="alert.message"
      @onClose="alert = { show: false, message: '' }"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Sharing from '../../common/dialogs/Sharing.vue'
import CellookButton from '@/components/common/buttons/CellookButton.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
import ProductCardImage from '../../common/widgets/ProductCardImage.vue'
import ProductCardY from '../../common/widgets/ProductCardY.vue'
import { GetMultiPromotionPlanx } from '../../../api/displayAPI/DisplayAPI'
import { DeleteSubscribe, PostSubscribe } from '../../../api/displayAPI/UserAPI'
import Alert from '@/components/common/dialogs/Alert.vue'
import FlipBanner from '@/components/common/layout/FlipBanner.vue'
import _ from 'lodash'

import FlipCountdown from 'vue2-flip-countdown'
export default Vue.extend({
  name: 'PromotionMultiMallView',
  components: {
    CellookButton,
    ProductCardImage,
    ProductCardY,
    VueSlickCarousel,
    Sharing,
    FlipCountdown,
    Alert,
  },
  data() {
    return {
      carouselInitData: {
        initCarouselIndex: 0,
        carouselListGroup: {},
      },
      scrollLocation: 0,
      promotionEndDate: '',
      promotionEndTime: '',
      promotionEndDt: '',
      promotionMultiShopList: [],
      paging: {},
      usePromotionCheck: false,
      showDays: false,
      subscribeCountArray: [],
      multiPromotionNo: 0,
      scroll: 0,
      showBanner: true,
      targetMallIndex: 0,
      share: false,
      shopId: '',
      targetScrollIndex: [],
      shareItem: {},
      shareProps: {},
      multiPromotionGroup: {},
      carouselOption: {
        centerMode: true,
        centerPadding: '50px',
        infinite: true,
        slidesToShow: 1,
        speed: 200,
        swipeToSlide: true,
        focusOnSelect: true,
      },
      GAData: {
        title: '',
        location: `${process.env.VUE_APP_CELLOOK_URL}/promotionMultiMall`,
        path: '/promotionMultiMall',
      },
      targetImage: 0,
      active: 0,
      datafetching: false,
      alert: {
        show: false,
        message: '',
      },
    }
  },
  created() {
    console.log('#')
    this.multiPromotionNo = this.$route.query.multiPromotionNo
    this.fetchLoading({ yn: true })
    this.$getAppHtml.addEventListener('scroll', this.handleScroll)
    //shopId에 맞게 JSON 데이터 바인딩
    this.setMultiMallData(true)
  },
  destroyed() {
    this.$getAppHtml.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    async isLogined() {
      this.fetchLoading({ yn: true })
      await this.setMultiMallData()
      this.fetchLoading({ yn: false })
    },
    scrollTopNoti() {
      this.$tracking(`collabo_${this.multiPromotionNo}_gototop`)
    },
  },
  computed: {
    ...mapState('BannerStore', ['onBanner']),
    ...mapState('CommonStore', ['scrollTopNoti']),
    headerAnimClass() {
      return this.onBanner ? 'common-header-visible' : 'common-header-animate'
    },
  },
  methods: {
    ...mapActions('HomeStore', ['fetchMainTab']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('ExhibitionStore', [
      'fetchEventExhibitionScrollLocation',
      'fetchPromotionMultiMallPaging',
      'fetchPromotionMultiMallCarouselInitData',
    ]),
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    ...mapActions('BannerStore', ['fetchOnBanner']),
    groupByShuffle() {
      const { searchProductList } =
        this.multiPromotionGroup.promotionInfo.representItem

      this.multiPromotionGroup.promotionInfo.representItem.searchProductList =
        _.shuffle(searchProductList)

      this.carouselInitData.carouselListGroup = [
        ...this.multiPromotionGroup.promotionInfo.representItem
          .searchProductList,
      ]
    },
    setScrollLocation() {
      if (this.scrollLocation) {
        this.$getAppHtml.scrollTo({
          top: this.scrollLocation,
        })
      } else {
        this.fetchOnBanner({ onBanner: true })
      }
    },
    setSubscribeCount(targetArray) {
      if (!this.datafetching) {
        targetArray.forEach((value) => {
          this.subscribeCountArray.push(value.subscribeCount)
        })
      }
    },
    setMultiMallData(init) {
      GetMultiPromotionPlanx({
        multiPromotionNo: this.multiPromotionNo,
      })
        .then((result) => {
          if (result) {
            this.GAData.title = result.data.gaInfo.title
            this.multiPromotionGroup = result.data
            this.multiPromotionGroup.shareInfo.url =
              `${process.env.VUE_APP_CELLOOK_URL}/promotionMultiMall?multiPromotionNo=` +
              this.multiPromotionNo

            if (localStorage.getItem('exhibitionEventScrollLocation')) {
              this.scrollLocation = Number(
                localStorage.getItem('exhibitionEventScrollLocation')
              )
            }

            if (localStorage.getItem('promotionMultiMallCarouselInitData')) {
              this.multiPromotionGroup.promotionInfo.representItem.searchProductList =
                JSON.parse(
                  localStorage.getItem('promotionMultiMallCarouselInitData')
                ).carouselListGroup
              this.carouselInitData.initCarouselIndex = JSON.parse(
                localStorage.getItem('promotionMultiMallCarouselInitData')
              ).initCarouselIndex
              this.carouselInitData.carouselListGroup = JSON.parse(
                localStorage.getItem('promotionMultiMallCarouselInitData')
              ).carouselListGroup
            } else {
              this.groupByShuffle()
            }
            this.setSubscribeCount(result.data.promotionInfo.multiShopList)
            const splitDate = result.data.endDt.split(' ')
            this.promotionEndDate = String(splitDate[0])
            this.promotionEndTime = String(splitDate[1])
            this.promotionEndDt =
              this.promotionEndDate.replaceAll('/', '-') +
              ' ' +
              this.promotionEndTime
            const today = this.getToday()
            const time = this.getTime()
            const fullDateToday = today + ' ' + time
            this.showDays = this.isCompareDate(fullDateToday, result.data.endDt)
            this.usePromotionCheck = this.isCompareFullDate(
              fullDateToday,
              result.data.endDt
            )
            this.multiPromotionGroup.promotionInfo.multiShopList.forEach(
              (value, index) => {
                this.paging[index] = {
                  page: {},
                }
                this.promotionMultiShopList[index] = _.cloneDeep(value)
              }
            )
            if (localStorage.getItem('promotionMultiMallPaging')) {
              this.paging = JSON.parse(
                localStorage.getItem('promotionMultiMallPaging')
              )
            }
            this.promotionMultiShopList.forEach((value, index) => {
              value.categoryList.forEach((_value, _index) => {
                if (!localStorage.getItem('promotionMultiMallPaging')) {
                  this.paging[index].page[_index] = {
                    page: 0,
                    limit: 20,
                  }
                }
                _value.paging = this.paging[index].page[_index]
                _value.searchProductList = _value.searchProductList.slice(
                  0,
                  (_value.paging.page + 1) * _value.paging.limit
                )
              })
            })
            this.datafetching = true
            localStorage.removeItem('exhibitionEventScrollLocation')
            localStorage.removeItem('promotionMultiMallPaging')
            localStorage.removeItem('promotionMultiMallCarouselInitData')
          }
        })
        .catch((error) => {
          this.$router.back()
          console.log(error)
        })
        .finally(() => {
          this.$trackingPromotion(this.GAData)
          this.initCarouselIndex(this.carouselInitData.initCarouselIndex)
          if (init) {
            setTimeout(() => {
              this.setScrollLocation()
            }, 500)
          }
          this.fetchLoading({ yn: false })
        })
    },
    onClickPrevIcon() {
      this.$tracking(`collabo_${this.multiPromotionNo}_back`)
      this.$router.push('/')
    },
    onClickShareIcon() {
      this.$tracking(`collabo_${this.multiPromotionNo}_share`)
      this.share = true
    },
    initCarouselIndex(index) {
      this.active = index
      this.targetImage = index
      this.carouselInitData.initCarouselIndex = index
      this.fetchPromotionMultiMallCarouselInitData(this.carouselInitData)
      this.$refs.carousel.goTo(index)
    },
    handleActive(active) {
      console.log('handleActive')
      this.active = active
      this.targetImage = active
      this.carouselInitData.initCarouselIndex = active
      this.fetchPromotionMultiMallCarouselInitData(this.carouselInitData)
    },
    onClickBlurImage(imageIndex) {
      console.log('onClickBlurImage')
      this.carouselInitData.initCarouselIndex = imageIndex
      this.$refs.carousel.goTo(imageIndex)
    },
    moveToStore(shopId, index) {
      this.$tracking(
        `collabo_${this.multiPromotionNo}_mall${this.$numbering(
          index + 1
        )}_storeHome`
      )
      this.setPromotionMultiMallInitData()
      this.$router.push({ name: 'StoreHome', query: { shopId: shopId } })
    },
    goMainMDSPick() {
      this.$tracking(`collabo_${this.multiPromotionNo}_mdpick`)
      this.fetchMainTab({ tab: 3 })
      this.$router.push('/')
    },
    handleScroll() {
      if (this.scroll > this.$getAppHtml.scrollTop) {
        this.showBanner = true
      } else {
        this.showBanner = false
      }
      this.scroll = this.$getAppHtml.scrollTop
      // // offsetTop = 시작위치 offsetHeight = div영역의 넓이
      try {
        for (
          let i = 0;
          i < this.multiPromotionGroup.promotionInfo.multiShopList.length;
          i++
        ) {
          if (
            this.$getAppHtml.scrollTop + 100 >=
              document.getElementById('area-' + i).offsetTop &&
            this.$getAppHtml.scrollTop <=
              document.getElementById('area-' + i).offsetTop +
                document.getElementById('area-' + i).offsetHeight
          ) {
            this.targetMallIndex = i
          }
        }
      } catch (e) {
        console.log('promotion multi mall scroll error')
      }

      this.fetchEventExhibitionScrollLocation(this.$getAppHtml.scrollTop)
      this.fetchPromotionMultiMallPaging(this.paging)
      this.fetchPromotionMultiMallCarouselInitData(this.carouselInitData)
    },
    goExhibitionList(promotionNo, index) {
      this.$tracking(
        `collabo_${this.multiPromotionNo}_other${this.$numbering(index + 1)}`
      )
      this.setPromotionMultiMallInitData()
      this.$router.push({
        name: 'CategoryExhibitionList',
        query: {
          promotionNo: promotionNo,
          previousPage:
            'promotionMultiMall?multiPromotionNo=' + this.multiPromotionNo,
        },
      })
    },
    setPromotionMultiMallInitData() {
      localStorage.setItem(
        'promotionMultiMallCarouselInitData',
        JSON.stringify(this.carouselInitData)
      )
      localStorage.setItem(
        'promotionMultiMallPaging',
        JSON.stringify(this.paging)
      )
      localStorage.setItem(
        'exhibitionEventScrollLocation',
        JSON.stringify(this.$getAppHtml.scrollTop)
      )
    },
    handleChange(index) {
      this.$tracking(
        `collabo_${this.multiPromotionNo}_navi_mall${this.$numbering(
          index + 1
        )}`
      )
      this.targetMallIndex = index
      this.$getAppHtml.scrollTo({
        top: document.getElementById('area-' + index).offsetTop - 65,
        behavior: 'auto',
      })
    },
    async toggleSubscribe(shopId, shopName, isSubscribed, index) {
      try {
        if (!this.isLogined) {
          await this.fetchLoginDialog({ yn: true })
          return
        }
        isSubscribed
          ? await this.unSubscribe(shopId, shopName, index)
          : await this.subscribe(shopId, shopName, index)
        await this.setMultiMallData(false)
      } catch (e) {
        console.log(e)
      }
    },
    async subscribe(shopId, shopName, index) {
      try {
        await PostSubscribe(shopId, shopName)
        this.$tracking(
          `collabo_${this.multiPromotionNo}_mall${this.$numbering(
            index + 1
          )}_subscribe`
        )
        this.subscribeCountArray[index] += 1
      } catch (e) {
        if (e && e.error_code === 400) {
          this.alert = { show: true, message: e.message }
        }
      }
    },
    async unSubscribe(shopId, shopName, index) {
      try {
        await DeleteSubscribe(shopId, shopName)
        this.$tracking(
          `collabo_${this.multiPromotionNo}_mall${this.$numbering(
            index + 1
          )}_unsubscribe`
        )
        this.subscribeCountArray[index] -= 1
      } catch (error) {
        console.log(error)
      }
    },
    getToday() {
      const today = new Date()
      const year = today.getFullYear()
      const month = ('0' + (today.getMonth() + 1)).slice(-2)
      const day = ('0' + today.getDate()).slice(-2)
      return year + '/' + month + '/' + day
    },
    getTime() {
      const today = new Date()
      const hours = ('0' + today.getHours()).slice(-2)
      const minutes = ('0' + today.getMinutes()).slice(-2)
      const seconds = ('0' + today.getSeconds()).slice(-2)
      return hours + ':' + minutes + ':' + seconds
    },
    isCompareDate(today, promotionEndDate) {
      const todayDate = new Date(today)
      const endDate = new Date(promotionEndDate)
      const calcTime = endDate.getTime() - todayDate.getTime()
      return calcTime / (1000 * 60 * 60 * 24).toFixed(1) > 1
    },
    isCompareFullDate(fullDateToday, endDt) {
      const todayDate = new Date(fullDateToday)
      const endDate = new Date(endDt)
      return todayDate < endDate
    },
    timeElapsedHandler() {
      this.usePromotionCheck = false
    },
    viewMore(targetIndex, _targetIndex) {
      sessionStorage.setItem('appInit', 'N')
      this.fetchLoading({ yn: true })
      this.paging[targetIndex].page[_targetIndex] = {
        page: 1,
        limit: 20,
      }
      GetMultiPromotionPlanx({
        multiPromotionNo: this.multiPromotionNo,
      })
        .then((result) => {
          if (result) {
            this.multiPromotionGroup = result.data
            this.multiPromotionGroup.promotionInfo.multiShopList.forEach(
              (value, index) => {
                if (targetIndex === index) {
                  this.promotionMultiShopList[index] = _.cloneDeep(value)
                }
              }
            )
            this.promotionMultiShopList.forEach((value, index) => {
              value.categoryList.forEach((_value, _index) => {
                _value.paging = this.paging[index].page[_index]
                _value.searchProductList = _value.searchProductList.slice(
                  0,
                  (_value.paging.page + 1) * _value.paging.limit
                )
              })
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.datafetching = true
          this.fetchLoading({ yn: false })
          this.fetchPromotionMultiMallPaging(this.paging)
        })
    },
    onCloseShare(type) {
      this.$tracking(`collabo_${this.singlePromotionNo}_share_${type}`)
      this.share = false
    },
    isLikeChange(yn, index, _index) {
      const trackingName = `collabo_${
        this.multiPromotionNo
      }_cate${this.$numbering(index + 1)}_${this.$numbering(_index + 1)}`

      if (yn) this.$tracking(`${trackingName}_like`)
      else this.$tracking(`${trackingName}_unlike`)
    },
  },
})
</script>
<style scoped>
.fixed-bar-exhibition-event-multi-tab {
  position: sticky;
  z-index: 103;
  height: 45px;
}

.txt_line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
